import React from "react";

// Libraries
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

// Components
import SocialIcons from "../SocialIcon/SocialIcons";
import FooterMenu, { Footer } from "./Footer";

// Data
import FooterData from "./FooterData";

const FooterMain = () => {
  const iconData = [
    {
      color: "#828282",
      link: "https://www.facebook.com/indiacounseling?mibextid=ZbWKwL",
      icon: "fab fa-facebook-f",
    },
    // {
    //   color: "#828282",
    //   link: "https://dribbble.com/",
    //   icon: "fab fa-dribbble",
    // },
    {
      color: "#828282",
      link: "https://instagram.com/counselling_india?igshid=OGQ5ZDc2ODk2ZA==",
      icon: "fab fa-instagram",
    },
    {
      color: "#828282",
      link: "https://www.linkedin.com/company/counselling-india/",
      icon: "fab fa-linkedin-in",
    },
  ];
  return (
    <div>
      <Footer
        topSpace={false}
        theme="light"
        className={`footer-style-04 finance-footer`}
      >
        <div className="py-[6%] pb-[2%] lg:py-[8%] md:py-[30px]">
          <Container>
            <Row className="justify-between md:justify-start pb-[3%] md:py-[20px]">
              <Col
                lg={{ span: 3, order: 0 }}
                sm={{ span: 6, order: 1 }}
                className="md:mb-[40px] xs:mb-[25px]"
              >
                <Link aria-label="link" to="/" className="mb-[25px] block">
                  <img
                    src="/assets/img/webp/MedicalCounsellingIndia/logo/brand-logo.webp"
                    alt="logo"
                    width="111"
                    height="36"
                  />
                </Link>
                <p className="w-full md:w-[80%] mb-[20px] xs:w-11/12">
                  Counseling India is a top notch company that has always
                  focused on students goal and aim to choose the best college
                  for a bright future. Feel free to connect us.
                </p>
                <div className=" text-[#f78c1f] font-bold cursor-pointer mb-[20px]">
                  <Link
                    to="/privacy-policy"
                    className="text-[#f78c1f] hover:text-[#f78c1f]"
                  >
                    Privacy & Policy
                  </Link>
                </div>

                <SocialIcons
                  theme="social-icon-style-01"
                  className="justify-start"
                  size="xs"
                  iconColor="dark"
                  data={iconData}
                />
              </Col>
              <FooterMenu
                data={FooterData.slice(0, 3)}
                lg={{ span: 2, order: 0 }}
                sm={{ span: 6, order: 2 }}
                xs={{ span: 6, order: 0 }}
                className="xs:mb-[25px]"
                titleClass="capitalize"
              />
              <Col
                xl={{ span: 3 }}
                lg={{ span: 4, order: 0 }}
                sm={{ span: 6, order: 3 }}
                md={5}
              >
                <span className="font-serif font-medium block text-themecolor mb-[20px] xs:mb-[10px]">
                  Address
                </span>
                <div className="mb-[25px] md:mb-[20px]">
                  <table>
                    <tbody>
                      <tr>
                        <th className="flex items-start">
                          <i className="line-icon-Geo2-Love text-gradient font-semibold bg-[#f78c1f] text-[25px]  inline-block translate-y-1"></i>
                        </th>
                        <td className="pb-3 pl-2">
                          3RD FLOOR, 303, BRAMHACORP VANTAGE TOWER C, OPPO -
                          BAVDHAN POLICE STATION, RAM NAGAR, PUNE - 411021
                        </td>
                      </tr>
                      <tr>
                        <th className="flex items-start">
                          <i className="line-icon-Mail-Read text-gradient font-semibold bg-[#f78c1f] text-[22px]  inline-block translate-y-1"></i>
                        </th>
                        <td className="pb-3 pl-2 ">
                          <a
                            href="mailto:info@counsellingindia.com"
                            className="hover:!text-[#f78c1f]"
                          >
                            info@counsellingindia.com
                          </a>
                        </td>
                      </tr>
                      <tr>
                        <th className="flex items-start">
                          <i className="line-icon-Telephone font-semibold text-gradient bg-[#f78c1f] text-[25px]  inline-block translate-y-1"></i>
                        </th>
                        <td className="pl-2 ">
                          <a
                            href="tel:+919730858444"
                            className="hover:!text-[#f78c1f]"
                          >
                            9730858444
                          </a>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </Col>
            </Row>
            <Row>
              <Col
                xl={{ span: 6 }}
                lg={{ span: 6, order: 0 }}
                sm={{ span: 12, order: 3 }}
                md={12}
              >
                <p>© 2023 Counselling India | All Rights Reserved.</p>
              </Col>
              <Col
                xl={{ span: 6 }}
                lg={{ span: 6, order: 0 }}
                sm={{ span: 12, order: 3 }}
                md={12}
              >
                <div className="flex items-center justify-end md:justify-start">
                  Developed By
                  <a
                    aria-label="link"
                    href="https://teckat.com"
                    target="_blank"
                    rel="noreferrer"
                    className=" text-themecolor font-medium hover:text-basecolor flex items-center"
                  >
                    <img
                      src="/assets/img/webp/MedicalCounsellingIndia/logo/teckat-logo.png"
                      alt="teckat logo"
                      className="w-[30px] mx-1"
                    />
                    <span className="hover:text-[#f78c1f]">Teckat.com</span>
                  </a>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </Footer>
    </div>
  );
};

export default FooterMain;
